<template>
  <div id="genreEdit">
    <app-menu></app-menu>
      <div class="col-lg-10 offset-lg-1">
        <h3 class="text-center">Žánr</h3>
        <div class="row pt-lg-4">
          <div class="col-lg-6">
            <h4 class="text-center">Čeština</h4>
            <form>
              <div class="form-group">
                <label for="formNameCz">Název:</label>
                <input type="text" class="form-control" id="formNameCz" v-model="genre.name.cs"
                       placeholder="Název žánru">
              </div>
              <div class="form-group">
                <label for="TextareaCz">Popis:</label>
                <textarea class="form-control" id="TextareaCz" v-model="genre.description.cs" rows="3"></textarea>
              </div>
            </form>
          </div>
          <div class="col-lg-6">
            <h4 class="text-center">Angličtina</h4>
            <form>
              <div class="form-group">
                <label for="formNameEn">Název:</label>
                <input type="text" class="form-control" id="formNameEn" v-model="genre.name.en"
                       placeholder="Název žánru">
              </div>
              <div class="form-group">
                <label for="TextareaEn">Popis:</label>
                <textarea class="form-control" id="TextareaEn" v-model="genre.description.en" rows="3"></textarea>
              </div>
            </form>
          </div>
        </div>
        <div class="text-center pt-lg-4 pb-lg-4">
          <label class="pr-lg-4" >Vyberte barvu žánru:</label>
          <input type="color" name="favcolor" v-model="genre.color">
        </div>
        <div class="text-center pt-lg-4">
          <send-button
              :send="create"
              text="Uložit Žánr"
              :response="error"
          />
        </div>
      </div>
    <error-modal :message="error"/>
  </div>
</template>

<script>
import axios from "@/api/axiom-instance";
import AdminMenu from "@/components/admin/AdminMenu";
import ErrorModal from "@/components/admin/ErrorModal";
import SendButton from "@/components/admin/SendButton";
export default {
  name: "ProgramGenre",
  components: {
    'app-menu': AdminMenu,
    'error-modal': ErrorModal,
    'send-button': SendButton
  },
  data() {
    return {
      genre: {name: {}, description: {}, color: "#000000"},
      error: null
    }
  },
  created() {
    if (this.$route.params.id != null) {
      this.load(this.$route.params.id)
    }
  },
  methods: {
    create: function () {
      axios.post("/genre", this.genre).then(() => {
        this.$router.push({path: "/admin/program/genre"})
      }).catch((error) => this.error = error)
    },
    load: function (id) {
      axios
          .get("/genre", {params: {id: id}})
          .then((success) => {
            this.genre = success.data;
          }).catch((error) => this.error = error)

    }
  }
}
</script>

<style scoped>
#genreEdit {
  background-color: white;
  height: 100vh;
}

</style>
